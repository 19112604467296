import { IonBadge, IonButton, IonCol, IonGrid, IonInput, IonItem, IonLoading, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Storage } from '@capacitor/storage';
import FirebaseFunctionUtil from '../../utilities/FirebaseFunctionsUtil';
import VerifyOtp from './VerifyOtp';
import { alreadyHaveOTPEvent, signinFailureEvent, signinSuccessEvent, newOTPRequestedEvent, signupSuccessEvent } from '../Analytics/ActionEvents';

type SignUpForm = {
  email: string;
  name: string;
  error: string;
}

const SignUp: React.FC<{
  firebase: any,
  redirectOnSuccess: string
}> = ({ firebase, redirectOnSuccess }) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [showSignIn, setShowSignIn] = useState<boolean>(true);
  const [showVerifyOtp, setShowVerifyOtp] = useState<boolean>(false);
  const [signupFailed, setSignupFailed] = useState<boolean>(false);

  const { register, handleSubmit, getValues, formState: { errors } } = useForm<SignUpForm>({
    defaultValues: {
      email: "",
      name: "",
    },
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const onSubmit = (data: SignUpForm) => {
    setLoading(true);

    FirebaseFunctionUtil.signupUser(firebase.functions, data.email)
      .then((res: any) => {
        console.log(res.data);
        if (res.data.status === "FAILED") {
          setSignupFailed(res.data.status === "FAILED");
          signinFailureEvent();
        } else if (res.data.status === "EXISTING_USER_SUCCESS") {
          signinSuccessEvent();
        } else if (res.data.status === "NEW_USER_SUCCESS") {
          signupSuccessEvent();
        }

        Storage.set({ key: 'requestOtp', value: "true" });
        Storage.set({ key: 'uid', value: res.data.uid });
        setShowVerifyOtp(true);
        setShowSignIn(false);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const alreadyHaveOtp = () => {

    setShowVerifyOtp(true);
    setShowSignIn(false);
    alreadyHaveOTPEvent();
  }

  const requestNewOtp = () => {

    setShowVerifyOtp(false);
    setShowSignIn(true);
    newOTPRequestedEvent();
  }

  return (
    <IonGrid >

      {showSignIn &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonText>
                <h2> Securely login with your email.</h2>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center ion-padding">
              <IonItem>
                <IonInput autofocus type="email" placeholder="Email" {...register("email", { required: true, minLength: 3, maxLength: 60 })}></IonInput>
                {errors.email && <IonBadge color="danger">Email is required</IonBadge>}
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center ion-padding-top">
              <IonButton className='primary' type="submit" expand="block"> Send OTP </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-center">
              <IonButton fill={'clear'} onClick={alreadyHaveOtp}> Already have OTP? </IonButton>
            </IonCol>
          </IonRow>
          {signupFailed &&
            <IonRow>
              <IonCol>
                <IonText color='danger'>
                  <h2> We are unable to sign up with your email. Please retry. </h2>
                </IonText>
              </IonCol>
            </IonRow>}
          <IonLoading
            cssClass='page-loading-status'
            isOpen={loading}
            message={'Signing up...'}
          />
        </form>
      }
      {
        showVerifyOtp &&
        <IonRow>
          <IonCol class="ion-text-center">
            <VerifyOtp firebase={firebase} redirectOnSuccess={''} userEmail={getValues('email')} />
            <IonButton fill={'clear'} onClick={requestNewOtp}> Request a new OTP? </IonButton>
          </IonCol>
        </IonRow>
      }
    </IonGrid>
  );
};

export default SignUp;
