import { addDoc, collection, deleteDoc, doc, Firestore, onSnapshot, updateDoc, writeBatch } from "firebase/firestore";

export default class FirebaseFirestoreUtil {

    static subscribeFlowFieldChanges(db: Firestore, flowTemplateId: string) {
        return new Promise((resolve, reject) => {
            onSnapshot(doc(db, "Flows", flowTemplateId), (doc) => {
                resolve(doc.data());
            });
            // TODO: Add catch statement
        });
    }

    static getUserProfile(db: Firestore, userId: string) {
        console.log(userId);
        return new Promise((resolve, reject) => {
            onSnapshot(doc(db, "UserProfiles", userId), (doc) => {
                resolve(doc.data());
            });
            // TODO: Add catch statement
        });
    }

    static getQuestionById(db: Firestore, questionId: string) {
        console.log(questionId);
        return new Promise((resolve, reject) => {
            onSnapshot(doc(db, "Questions", questionId), (doc) => {
                resolve(doc.data());
            });
            // TODO: Add catch statement
        });
    }

    static async getTimezonePreference(db: Firestore, userId?: string) {
        var timezonePreference = Intl.DateTimeFormat().resolvedOptions().timeZone as string;
        /*
        TODO Enable this when we need to support multi timezone selection
        if (userId) {
            await FirebaseFirestoreUtil.getUserProfile(db, userId).then((result: any) => {
                if (!result || !result.timezonePreference || result.timezonePreference === "") {
                    // return timezonePreference;
                } else {
                    timezonePreference = result.timezonePreference;
                }
            });
        }
        */
        return timezonePreference;
    }

    static getFlowById(db: Firestore, collectionName: string, flowId: string) {

        return new Promise((resolve, reject) => {
            onSnapshot(doc(db, collectionName, flowId), (doc) => {
                resolve(doc.data());
            });
            // TODO: Add catch statement
        });
    }

    static updateFlow(db: Firestore, collectionName: string, flowId: string, objectValue: any) {
        const flowRef = doc(db, collectionName, flowId);
        updateDoc(flowRef, objectValue)
            .then((data) => {
                // console.log(data);
            });
    }

    static updateFlowWithPromise(db: Firestore, collectionName: string, flowId: string, objectValue: any) {
        const flowRef = doc(db, collectionName, flowId);
        return updateDoc(flowRef, objectValue)
    }

    static async createDoc(db: Firestore, collectionName: string, object: any) {
        this.createDateInfo(object);
        const docRef = await addDoc(collection(db, collectionName), object);
        return docRef.id;
    }

    static async createSubDoc(db: Firestore, collectionName: string, collectionKey: string,
        subCollectionName: string, object: any) {

        this.createDateInfo(object);

        const docRef = await addDoc(collection(db, collectionName, collectionKey, subCollectionName), object);
        return docRef.id;
    }

    static async removeDoc(db: Firestore, collectionName: string, stepId: string) {
        await deleteDoc(doc(db, collectionName, stepId));
    }

    static async updateDoc(db: Firestore, collectionName: string, stepId: string, key: string, value: any) {
        if (value === undefined) {
            value = "";
        }
        const stepRef = doc(db, collectionName, stepId);
        var object: any = {};
        object[key] = value;

        console.log(object);

        await updateDoc(stepRef, object)
            .then((data) => {
                // console.log("Data:" + data);
            });
    }

    static async updateDocWithMultipleKeys(db: Firestore, collectionName: string, stepId: string, keyValues: any) {
        if (keyValues === undefined) {
            return;
        }

        const stepRef = doc(db, collectionName, stepId);

        await updateDoc(stepRef, keyValues)
            .then((data) => {
                // console.log("Data:" + data);
            });
    }


    static async batchUpdateSteps(db: Firestore, collectionName: string, stepsInfo: any[]) {
        const batch = writeBatch(db);

        stepsInfo.forEach(stepInfo => {
            const ref = doc(db, collectionName, stepInfo.stepId);

            batch.update(ref, { seq: stepInfo.seq });
        })

        await batch.commit();

    }

    private static createDateInfo(objectValue: any) {
        if (!objectValue.createdOn) {
            objectValue.createdOn = Date.now()
        }
    }
}

