import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import ViewEvents from '../Analytics/ViewEvents';
import { QrReader } from 'react-qr-reader';

const QrScanModel = ({
    onDismiss
}: { onDismiss }) => {

    const [scanResult, setScanResult] = useState<string>();

    useEffect(() => {
        if (scanResult?.startsWith("https://beta.stepssy.com/") || scanResult?.startsWith("https://stepssy.com/")) {
            window.location.replace(scanResult);
        }
    }, [scanResult])

    useEffect(() => {
        ViewEvents.sendModalViewEvent('ScanQr');
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => onDismiss()}>
                            <IonIcon src={'./assets/icon/back.svg'} size="large" />
                        </IonButton>
                    </IonButtons>

                    <IonTitle>Scan QR code</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonRow className='ion-text-center ion-padding-top'>
                    <IonCol>
                        <IonText>
                            Scan another QR code to view another product.
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <QrReader
                            containerStyle={{ width: "100%" }}
                            videoStyle={{ width: "100%" }}
                            className={'qr-scanner'}
                            videoId={'qr-scanner'}
                            onResult={(res: any, err: any) => {
                                if (!!res) {
                                    // Fetch the result.
                                    // Note: QR reader will also stop after scanning.
                                    setScanResult(res.getText());
                                    // you can add some logic here e.g., redirect to other pages after scanning etc.
                                }
                                if (!!err) {
                                    //console.info(err);
                                }
                            }}
                            constraints={{ facingMode: 'environment' }} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default QrScanModel;
