import { Functions, httpsCallable } from 'firebase/functions';
import { useImperativeHandle } from 'react';

export default class FirebaseFunctionUtil {
    static searchFlowTemplates(functions: Functions, searchString: string) {
        const searchFlowTemplates = httpsCallable(functions, 'searchFlowTemplatesCallable');
        return searchFlowTemplates({
            searchString: searchString
        });
    }

    static getUserFlowTemplates(functions: Functions) {
        const userFlowTemplates = httpsCallable(functions, 'searchFlowTemplatesCallable');
        return userFlowTemplates({
            criteria: "userId"
        });
    }

    static getFlowTemplateById(functions: Functions, flowId: string) {
        const loadByTypeAndId = httpsCallable(functions, 'searchFlowTemplatesCallable');
        return loadByTypeAndId({
            criteria: "flowId",
            flowId: flowId
        });
    }

    static getFlowInstanceById(functions: Functions, flowInstanceId: string) {
        const loadByTypeAndId = httpsCallable(functions, 'loadByTypeAndId');
        return loadByTypeAndId({
            type: "FlowInstance",
            id: flowInstanceId
        });
    }

    static getMyFlowInstances(functions: Functions, status: string) {
        const getMyFlowInstances = httpsCallable(functions, 'getMyFlowInstances');
        return getMyFlowInstances({
            status: status
        });
    }

    static getBookmarkedFlows(functions: Functions) {
        const getBookmarkedFlows = httpsCallable(functions, 'getMyBookmarkedTemplates');
        return getBookmarkedFlows({
        });
    }

    static startFlow(functions: Functions, flowTemplateId: string) {
        const startFlow = httpsCallable(functions, 'startFlow');
        return startFlow({
            flowTemplateId: flowTemplateId
        });
    }

    static updateFlowProperties(functions: Functions, flowTemplateId: string, propertyName: string, result: boolean) {
        const updateFlowTemplate = httpsCallable(functions, 'updateFlowProperties');
        let action = result ? 'add' : 'remove';

        updateFlowTemplate({
            flowTemplateId: flowTemplateId,
            propertyName: propertyName,
            action: action
        })
            .then((result) => {
                // console.log(result);
            });
    }

    static updateQuestionProperties(functions: Functions, questionId: string, propertyName: string, result: boolean) {
        const updateQuestionProperties = httpsCallable(functions, 'updateQuestionProperties');
        let action = result ? 'add' : 'remove';

        updateQuestionProperties({
            questionId: questionId,
            propertyName: propertyName,
            action: action
        })
            .then((result) => {
                console.log(result);
            });
    }

    static deleteFlowInstance(functions: Functions, flowInstanceId: string) {
        const removeFlowByTypeAndId = httpsCallable(functions, 'removeFlowByTypeAndId');
        return removeFlowByTypeAndId({
            id: flowInstanceId,
            type: "FlowInstance"
        });
    }

    static updateFlowInstanceStatus(functions: Functions, flowInstanceId: string, status: string) {
        const removeFlowByTypeAndId = httpsCallable(functions, 'updateFlowInstanceProperties');
        return removeFlowByTypeAndId({
            id: flowInstanceId,
            propertyName: "status",
            propertyValue: status
        });
    }

    static updateCheckboxStep(functions: Functions, stepId: string, status: boolean) {
        const updateStepProperties = httpsCallable(functions, 'updateStepProperties');
        return updateStepProperties({
            id: stepId,
            type: 'CHECK_BOX',
            status: status
        });
    }

    static initiateFlowTemplate(functions: Functions, data: any) {
        const initiateFlowTemplate = httpsCallable(functions, 'initiateFlowTemplate');

        return initiateFlowTemplate(data);
    }

    static postQuestion(functions: Functions, data: any) {
        const postQuestion = httpsCallable(functions, 'postQuestion');

        return postQuestion(data);
    }

    static listLatestQuestions(functions: Functions) {
        const listQuestions = httpsCallable(functions, 'listQuestions');

        return listQuestions();
    }

    static startAnswer(functions: Functions, questionId: string, question: string, stepsType: string) {
        const startAnswer = httpsCallable(functions, 'startAnswer');
        return startAnswer({
            questionId: questionId,
            question: question,
            stepsType: stepsType
        });
    }

    static signupUser(functions: Functions, email: string) {
        const signupUser = httpsCallable(functions, 'signupUser');
        return signupUser({
            email: email
        });
    }

    static setTimezonePreference(functions: Functions, timezonePreference: any) {
        const setTimezonePreference = httpsCallable(functions, 'setTimezonePreference');
        return setTimezonePreference({
            timezonePreference: timezonePreference
        });
    }

    static verifyUserOtp(functions: Functions, uid: string, otp: string) {
        const verifyOtp = httpsCallable(functions, 'verifyotp');
        return verifyOtp({
            uid: uid,
            otp: otp
        });
    }
}