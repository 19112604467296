import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonNote, IonPage, IonRadio, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { signoutFailureEvent, signoutSuccessEvent } from '../../components/Analytics/ActionEvents';
import AppHeader from '../../components/Header/AppHeader';
import { useFirebase } from '../../context/Firebase/FirebaseContext';
import FirebaseAuthUtil from '../../utilities/FirebaseAuthUtil';
import './MyAccount.css';
import { getDownloadURL, ref } from 'firebase/storage';

const MyAccount: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);

  const firebase = useFirebase();

  const signOut = () => {
    setLoading(true);
    FirebaseAuthUtil.signOut(firebase.auth)
      .then(() => {
        console.log("User signed out successfully");
        setLoading(false);
        window.location.replace("/");
        signoutSuccessEvent();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        signoutFailureEvent();
      });
  };

  useEffect(() => {
    if (firebase.user) {
      setEmail(firebase.user?.email!);
      setEmailVerified(firebase.user?.emailVerified!);
    }
  }, [firebase.user]);

  return (
    <IonPage>
      <AppHeader
        loading={loading}
        showLoading={true}
        showProgressBar={true}
        subHeaderMessage={'My Account'}
        showMenuButton={false}
        showNavActions={true}
        showBackButton={false}
        showLogo={true} />

      <IonContent fullscreen>
        <IonGrid fixed>
          {
            firebase.isRecognizedUser && firebase.user &&
            <IonRow>
              <IonCol sizeMd='8' offsetMd='2'>
                <IonList>
                  <IonItem>
                    <IonLabel> Email </IonLabel>
                    <IonNote slot="end">{email}</IonNote>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      Email verified
                    </IonLabel>
                    <IonNote slot="end"> {emailVerified ? 'Yes' : 'No'} </IonNote>
                  </IonItem>
                </IonList>

                <IonItem href='/my-steps' detail={true}>
                  <IonLabel>
                    Show my steps
                  </IonLabel>
                </IonItem>

                <IonButton className='primary' type="button" expand="block" onClick={() => signOut()}>
                  Signout
                </IonButton>
              </IonCol>
            </IonRow>
          }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyAccount;
