import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonInput, IonLabel, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ViewEvents from '../Analytics/ViewEvents';

const VerfiyEmailModel: React.FC<{
    showError: boolean
    setUserEnteredEmail: (email: string) => void
    onDismiss: () => void;
}> = ({ showError, setUserEnteredEmail, onDismiss }) => {

    const [loading, setLoading] = useState<boolean>(false);

    type VerifiyEmailForm = {
        email: string;
        error: string;
    }

    const { register, handleSubmit, getValues, formState: { errors } } = useForm<VerifiyEmailForm>({
        defaultValues: {
            email: "",
        },
        mode: "onSubmit",
        reValidateMode: "onChange"
    });

    const onSubmit = (data: VerifiyEmailForm) => {
        setUserEnteredEmail(data.email);
    };

    useEffect(() => {
        ViewEvents.sendModalViewEvent('verifyEmail');
    }, []);

    return (
        <IonCard>
            <IonToolbar>
                <IonTitle>Verify Email</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={() => onDismiss()}>
                        <IonIcon md={closeOutline} ios={closeOutline}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonCardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonLabel>Re-Enter the email that you have registered. </IonLabel>
                                <IonInput type="email" placeholder="Email" {...register("email", { required: true, minLength: 3, maxLength: 30 })}></IonInput>
                                {errors.email && <IonBadge color="danger">Email is required</IonBadge>}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton type="submit" expand="block"> Verify </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                {showError && <IonBadge color="danger">Unable to verify your email. Please retry Signin again.</IonBadge>}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
            </IonCardContent>
        </IonCard>
    );
};


export default VerfiyEmailModel;