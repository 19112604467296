import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonIcon,
    IonImg,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonProgressBar,
    IonTitle,
    IonToolbar,
    useIonModal,
} from '@ionic/react';
import { personCircle, qrCodeOutline, shareOutline, shareSocial, shareSocialOutline } from 'ionicons/icons';
import { useFirebase } from '../../context/Firebase/FirebaseContext';
import { CanShareResult, Share } from '@capacitor/share';

import './AppHeader.css';
import QrScanModel from '../QrScanModel/QrScanModel';
import { WhiteLabel } from '@steps-app/types/lib/WhiteLabel';
import { useEffect, useState } from 'react';

interface HeaderData {
    loading: boolean,
    showLoading: boolean,
    showProgressBar: boolean,
    showMenuButton: boolean,
    showNavActions: boolean,
    showBackButton: boolean,
    subHeaderMessage: string,
    showLogo: boolean,
    whiteLabel?: WhiteLabel
}

const AppHeader: React.FC<HeaderData> = (data: HeaderData) => {

    const firebase = useFirebase();
    const [shareAvailable, setShareAvailable] = useState<boolean>(false);

    const gotoHome = () => {
        window.location.href = "/";
    }

    const gotoWhiteLabel = () => {
        window.location.href = data.whiteLabel!.url;
    }

    const handleQrScanModel = () => {
        dismissQrScanModel();
    };

    const [presentQrScanModel, dismissQrScanModel] = useIonModal(QrScanModel, {
        onDismiss: handleQrScanModel
    });

    const shareSteps = async () => {
        await Share.share({
            title: document.title,
            text: document.title,
            url: window.location.href,
            dialogTitle: 'Share',
        });
    }

    useEffect(() => {
        Share.canShare()
            .then((data: CanShareResult) => {
                setShareAvailable(data.value);
            })
    }, []);

    return (
        <IonToolbar className='ion-no-padding'>
            {
                data.showMenuButton && <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
            }
            {
                data.showBackButton && <IonButtons slot="start">
                    <IonBackButton defaultHref='/' text={"Back"} />
                </IonButtons>
            }
            {
                firebase.isRecognizedUser && data.showNavActions &&
                <IonButtons slot="end">
                    <IonButton href='/my-account' >
                        <IonIcon icon={personCircle}></IonIcon>
                    </IonButton>
                </IonButtons>
            }
            {
                data.showNavActions &&
                <IonButtons slot="end">
                    <IonButton onClick={() => presentQrScanModel()} >
                        <IonIcon icon={qrCodeOutline} ></IonIcon>
                    </IonButton>
                    {shareAvailable &&
                        <IonButton onClick={() => shareSteps()} >
                            <IonIcon icon={shareSocialOutline}></IonIcon>
                        </IonButton>}
                </IonButtons>
            }
            {
                data.whiteLabel && <IonTitle>
                    <IonLabel>
                        <IonImg className='white-label-logo'
                            src={data.whiteLabel.imageUrl}
                            onClick={() => gotoWhiteLabel()}
                        />
                    </IonLabel>
                </IonTitle>
            }
            {
                data.showLogo && <IonButtons slot="start">
                    <IonButton onClick={() => gotoHome()} >
                        <IonIcon src={"./assets/icon/stepssy.svg"} size='large' />
                    </IonButton>
                </IonButtons>
            }
            {
                data.loading && data.showProgressBar &&
                <IonProgressBar type="indeterminate" color='dark'></IonProgressBar>
            }

            {
                data.showLoading && <IonLoading
                    cssClass='page-loading-status'
                    isOpen={data.loading}
                    message={'Please wait...'} />
            }
        </IonToolbar>
    );
};

export default AppHeader;

