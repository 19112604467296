import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow, } from '@ionic/react';
import React, { useEffect } from 'react';
import AppHeader from '../../components/Header/AppHeader';
import { useFirebase } from '../../context/Firebase/FirebaseContext';
import SignUp from '../../components/SignUp/SignUp';

const SignInPasswordlessPage: React.FC = () => {

    const firebase = useFirebase();
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        if (firebase.isRecognizedUser) {
            window.location.replace("/my-account");
        }
    }, [firebase])

    return (
        <IonPage>
            <AppHeader
                loading={loading}
                showLoading={true}
                showProgressBar={true}
                subHeaderMessage={''}
                showMenuButton={true}
                showNavActions={false}
                showBackButton={false}
                showLogo={true} />

            <IonContent fullscreen>
                <IonGrid fixed>
                    <IonRow>
                        <IonCol sizeSm='8' offsetSm='2' sizeLg='6' offsetLg='3'>
                            <SignUp firebase={firebase} redirectOnSuccess={'/'} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonPage>
    );
};

export default SignInPasswordlessPage;
