import { IonButton, IonCol, IonLoading, IonRow, IonText } from '@ionic/react';
import React, { useState } from 'react';
import OtpInput from "react-otp-input";
import FirebaseAuthUtil from '../../utilities/FirebaseAuthUtil';
import { Storage } from '@capacitor/storage';
import FirebaseFunctionUtil from '../../utilities/FirebaseFunctionsUtil';
import { otpVerificationFailureEvent, otpVerificationSuccessEvent } from '../Analytics/ActionEvents';
import './SignUp.css'
const VerifyOtp: React.FC<{
    firebase: any,
    redirectOnSuccess: string
    userEmail: string
}> = ({ firebase, redirectOnSuccess, userEmail }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [verificationFailed, setVerificationFailed] = useState<boolean>(false);
    const [invalidOtp, setInvalidOtp] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>('');

    const handleOtpOnChange = (otp: string) => {
        setOtp(otp);
        setInvalidOtp(false);
    }

    const validateOtp = async () => {
        if (otp.length < 6) {
            setInvalidOtp(true);
            return;
        }

        setLoading(true);

        Storage.get({ key: 'uid' })
            .then((uid) => {
                FirebaseFunctionUtil.verifyUserOtp(firebase.functions,
                    uid.value!, otp)
                    .then((res: any) => {
                        console.log(res);
                        if (res.data.error) {
                            var error = res.data.error;
                            if (error === "OTP_VERIFIED_BUT_AUTH_FAILED" ||
                                error === "VERIFICATION_FAILED" ||
                                error === "Invalid otp or id") {

                                setVerificationFailed(true);
                                setOtp('')
                                otpVerificationFailureEvent();
                            }
                        } else {
                            FirebaseAuthUtil.signInWithCustomToken(firebase.auth,
                                res.data.authToken)
                                .then(() => {
                                    otpVerificationSuccessEvent();
                                })
                            Storage.remove({ key: 'requestOtp' });
                            Storage.remove({ key: 'uid' });
                        }
                    })
                    .catch((e: any) => {
                        setVerificationFailed(true);
                        setOtp('')
                        otpVerificationFailureEvent();
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            });
    }

    return (
        <>
            <IonRow>
                <IonCol class="ion-text-center">
                    <IonText>
                        <h2> One time password (OTP) has been sent to your <u>{userEmail ? userEmail : "email"}</u>.
                            Please enter OTP to sign in. </h2>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol class="ion-text-center ion-padding otp-col">
                    <OtpInput
                        value={otp}
                        onChange={handleOtpOnChange}
                        numInputs={6}
                        separator={<span>&nbsp; &nbsp; &nbsp;</span>}
                        isInputNum={true}
                        inputStyle={{
                            width: "30px",
                            height: "40px"
                        }}
                        shouldAutoFocus={true}
                    >
                    </OtpInput>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol class="ion-text-center ion-padding-top">
                    <IonButton className='primary' type="submit" expand="block" onClick={validateOtp}> Verify </IonButton>
                </IonCol>
            </IonRow>
            {verificationFailed &&
                <IonRow>
                    <IonCol>
                        <IonText color='danger'>
                            <h2> We are unable to verify the OTP. Please retry. </h2>
                        </IonText>
                    </IonCol>
                </IonRow>}
            {invalidOtp &&
                <IonRow>
                    <IonCol>
                        <IonText color='danger'>
                            <h2> Invalid OTP </h2>
                        </IonText>
                    </IonCol>
                </IonRow>}
            <IonLoading
                cssClass='page-loading-status'
                isOpen={loading}
                message={'Verifying...'}
            />
        </>
    );
};

export default VerifyOtp;
