import {
    Auth, createUserWithEmailAndPassword, EmailAuthProvider, linkWithCredential,
    sendEmailVerification, signInAnonymously, signInWithEmailAndPassword, signOut,
    sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink,
    User,
    signInWithCustomToken
} from 'firebase/auth';

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: window.location.origin + "/sign-in",
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //     bundleId: 'com.thestepsapp.ios'
    // },
    // android: {
    //     packageName: 'com.thestepsapp.android',
    //     installApp: true,
    //     minimumVersion: '12'
    // },
    // dynamicLinkDomain: '127.0.0.1'
};

export default class FirebaseAuthUtil {

    static signUpWithEmailAndPassword(auth: Auth, email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    static sendVerificationEmail(user: User) {
        sendEmailVerification(user);
    }

    static signInWithEmailAndPassword(auth: Auth, email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    static signOut(auth: Auth) {
        return signOut(auth);
    }

    static signInAnonymously(auth: Auth) {
        signInAnonymously(auth)
            .then(() => {
                console.log("User signed in anonymously");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    static linkAnonymousUserToSignInUser(auth: Auth, email: string, password: string) {
        const credential = EmailAuthProvider.credential(email, password);
        linkWithCredential(auth.currentUser!, credential)
            .then(() => {
                console.log("Anonymous account successfully upgraded");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    static sendPasswordResetEmail(auth: Auth, email: string) {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log("password reset email sent");
            })
            .catch((error) => {
                console.log(error);
            });
    }

    static sendSignInLinkToEmail(auth: Auth, email: string, redirectOnSuccess: string) {
        actionCodeSettings.url = window.location.origin + redirectOnSuccess;
        return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    }

    static isSignInWithEmailLink(auth: Auth, url: string) {
        return isSignInWithEmailLink(auth, url);
    }

    static signInWithEmailLink(auth: Auth, email: string, url: string) {
        return signInWithEmailLink(auth, email, url);

        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        // Storage.get({ key: 'emailForSignIn' })
        //     .then((emailForSignIn) => {
        //         // The client SDK will parse the code from the link for you.
        //         signInWithEmailLink(auth, email, window.location.href)
        //             .then((result) => {
        //                 // Clear email from storage.
        //                 Storage.remove({ key: 'emailForSignIn' });
        //                 // You can access the new user via result.user
        //                 // Additional user info profile not available via:
        //                 // result.additionalUserInfo.profile == null
        //                 // You can check if the user is new or existing:
        //                 // result.additionalUserInfo.isNewUser
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //                 // Some error occurred, you can inspect the code: error.code
        //                 // Common errors could be invalid email and invalid or expired OTPs.
        //             });
        //     });
    }

    static signInWithCustomToken(auth: Auth, token: string) {
        return signInWithCustomToken(auth, token);
    }
}