import ReactGA from "react-ga4";

const CATEGORY_USER_ACTIONS = 'UserActions';
const CATEGORY_FLOW_ACTIONS = 'FlowActions';
const CATEGORY_STEP_ACTIONS = 'StepActions';

export const signupSuccessEvent = () => userActionsEvent('Signup Success');
export const signupFailureEvent = () => userActionsEvent('Signup Failure');
export const signinSuccessEvent = () => userActionsEvent('Signin Success');
export const signinFailureEvent = () => userActionsEvent('Signin Failure');
export const signoutSuccessEvent = () => userActionsEvent('Signout Success');
export const signoutFailureEvent = () => userActionsEvent('Signout Failure');
export const newOTPRequestedEvent = () => userActionsEvent('New OTP Requested');
export const alreadyHaveOTPEvent = () => userActionsEvent('Already have OTP');
export const otpVerificationFailureEvent = () => userActionsEvent('OTP Verification Failure');
export const otpVerificationSuccessEvent = () => userActionsEvent('OTP Verification Success');

export const flowCreationSuccessEvent = () => flowActionsEvent('Flow Creation Success');
export const flowCreationFailureEvent = () => flowActionsEvent('Flow Creation Failure');
export const flowDeletionSuccessEvent = () => flowActionsEvent('Flow Deletion Success');
export const flowDeletionFailureEvent = () => flowActionsEvent('Flow Deletion Failure');
export const flowDeletionCancelEvent = () => flowActionsEvent('Flow Deletion Cancel');
export const flowShareSuccessEvent = () => flowActionsEvent('Flow Share Success');
export const flowUnshareSuccessEvent = () => flowActionsEvent('Flow Unshare Success');
export const flowShareCancelEvent = () => flowActionsEvent('Flow Share Cancel');
export const flowLikeEvent = () => flowActionsEvent('Flow Like');
export const flowUnlikeEvent = () => flowActionsEvent('Flow Unlike');
export const flowBookmarkEvent = () => flowActionsEvent('Flow Bookmark');
export const flowUnbookmarkEvent = () => flowActionsEvent('Flow Unbookmark');
export const flowCreationSuccessByTypeEvent = (stepType: string) => flowActionsEvent('Flow Creation Success : ' + stepType);
export const flowCreationFailureByTypeEvent = (stepType: string) => flowActionsEvent('Flow Creation Failure : ' + stepType);
export const flowInstanceSuccessEvent = () => flowActionsEvent('Flow Instance Creation Success');
export const flowInstanceFailureEvent = () => flowActionsEvent('Flow Instance Creation Failure');

export const stepCreationSuccessEvent = () => stepActionsEvent('Step Creation Success');
export const stepCreationFailureEvent = () => stepActionsEvent('Step Creation Failure');
export const stepDeletionSuccessEvent = () => stepActionsEvent('Step Deletion Success');
export const stepDeletionCancelEvent = () => stepActionsEvent('Step Deletion Cancel');
export const stepDeletionFailureEvent = () => stepActionsEvent('Step Deletion Failure');

function userActionsEvent(action: string) {
    genericEvent(CATEGORY_USER_ACTIONS, action);
}

function flowActionsEvent(action: string) {
    genericEvent(CATEGORY_FLOW_ACTIONS, action);
}
function stepActionsEvent(action: string) {
    genericEvent(CATEGORY_STEP_ACTIONS, action);
}

function genericEvent(category: any, action: string) {
    console.log(action)
    ReactGA.event({
        category: category,
        action: action
    });
}
