import ReactGA from "react-ga4";

export default class ViewEvents {

    static sendModalViewEvent(modalName: string) {
        ReactGA.send({ hitType: "pageview", page: "/modal/" + modalName });
    }

    static sendOneLevelPageViewBasedOnLocation() {
        var shortedPagePath = this.getOneLevelPath(window.location.pathname)
        console.log("Pageview event" + shortedPagePath);
        if(shortedPagePath) {
            ReactGA.send({ hitType: "pageview", page: shortedPagePath });
        } else {
            ReactGA.send({ hitType: "pageview", page: "invalidPagePath" });
        }
    }

    private static getOneLevelPath(path: string) {
        if(path) {
            var pathParts = window.location.pathname.split('/');
            if(pathParts && pathParts[1]) {
                return "/" + pathParts[1];
            }
        }
        return path;
    }

}
