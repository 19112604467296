import { IonContent, IonImg, IonPage } from '@ionic/react';
import './Splash.css';

const Splash: React.FC = () => {
    return (
        <IonPage>
            <IonContent className='splash-screen' fullscreen>
                <IonImg src={"./assets/icon/stepssy.svg"} style={{
                    top: '35%',
                    bottom: '35%',
                    right: '35%',
                    left: '35%',
                    position: 'fixed'
                }} />
            </IonContent>
        </IonPage>
    );
};

export default Splash;

