import { IonApp, IonLabel, IonRouterOutlet, IonSplitPane, setupIonicReact, useIonModal } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MyAccount from './pages/MyAccount/MyAccount';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useFirebase } from './context/Firebase/FirebaseContext';

import VerfiyEmailModel from './components/SignUpModel/VerfiyEmailModel';
import SignInPasswordlessPage from './pages/SignIn/SignInPasswordlessPage';
import ReactGA from "react-ga4";
import ViewEvents from './components/Analytics/ViewEvents';

import './App.css';
import Splash from './components/Splash/Splash';

const HomePage = lazy(() => import('./pages/Home/HomePage'));
const Follow = lazy(() => import('./pages/Follow/Follow'));
const FeedbackPage = lazy(() => import('./pages/Feedback/FeedbackPage'));
const MyFlowsPage = lazy(() => import('./pages/MyFlows/MyFlowsPage'));
const PlayGroundPage = lazy(() => import('./pages/PlayGround/PlayGround'));


const App: React.FC = () => {

  const firebase = useFirebase();
  const [reEnteredEmail, setReEnteredEmail] = useState<string>("");
  const [showVerifyEmailError, setShowVerifyEmailError] = useState(false);
  const ga4id = "G-3FKYFKHV3D";

  setupIonicReact();

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(VerfiyEmailModel, {
    showError: showVerifyEmailError,
    onDismiss: handleDismiss,
    setUserEnteredEmail: setReEnteredEmail,
  });

  useEffect(() => {
    // https://beta.thestepsapp.com
    // TODO Reduce this to smaller number when we have decent amount of traffic to analyse
    ReactGA.initialize(ga4id, { gtagOptions: { 'siteSpeedSampleRate': 100 } });
    ViewEvents.sendOneLevelPageViewBasedOnLocation();
  }, []);

  return (
    <Suspense fallback={<Splash />}>
      {
        //We need to make sure the user is present before the page rendering to avoid
        // flickering / disappaering of page
        <IonApp>
          <IonReactRouter forceRefresh={true}>
            <IonSplitPane contentId="main" className='ion-split-pane'>
              <IonRouterOutlet id="main">
                <Switch>
                  <Route path="/" exact={true}>
                    <HomePage />
                  </Route>
                  <Route path="/steps/:flowId/" exact={true}>
                    <Follow />
                  </Route>
                  <Route path="/signin" exact={true}>
                    <SignInPasswordlessPage />
                  </Route>
                  <Route path="/my-account" exact={true}>
                    <MyAccount />
                  </Route>
                  <Route path="/feedback" exact={true}>
                    <FeedbackPage />
                  </Route>
                  <Route path="/my-steps" exact={true}>
                    <MyFlowsPage />
                  </Route>
                  <Route path="/playground/:playgroundTexture/" exact={true}>
                    <PlayGroundPage />
                  </Route>
                  <Route path="*">
                    <Redirect to={"/"} />
                  </Route>
                </Switch>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      }
    </Suspense>
  );
};

export default App;
