//https://dev.to/dchowitz/react-firebase-a-simple-context-based-authentication-provider-1ool
import { connectFirestoreEmulator, Firestore, getFirestore } from '@firebase/firestore';
import { Functions, getFunctions } from '@firebase/functions';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { FirebaseStorage, getStorage, ref } from "firebase/storage";
import { initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator } from 'firebase/functions';
import React from 'react';
import FirebaseFirestoreUtil from '../../utilities/FirebaseFireStoreUtil';
import { User } from '@steps-app/types/lib/User';
import FirebaseAuthUtil from '../../utilities/FirebaseAuthUtil';

type AuthenticatedUser = User | null;
type ContextState = {
  user: AuthenticatedUser,
  isRecognizedUser: boolean,
  auth: Auth,
  functions: Functions,
  db: Firestore,
  storage: FirebaseStorage
  profile: any
}

const FirebaseContext = React.createContext<ContextState | undefined>(undefined);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const FirebaseProvider: React.FC = ({ children }) => {
  const app: FirebaseApp = initializeApp(firebaseConfig);

  const [user, setUser] = React.useState<AuthenticatedUser>(null);
  const [isRecognizedUser, setIsRecognizedUser] = React.useState<boolean>(false);
  const [profile, setProfile] = React.useState<Promise<any>>();

  const auth = getAuth(app);
  // Updating locations.
  // const functions = getFunctions(app, "asia-south1");
  const functions = getFunctions(app);

  initializeFirestore(app, {
    ignoreUndefinedProperties: true,

    // https://stackoverflow.com/questions/71045643/could-not-reach-cloud-firestore-backend-react-native-firebase-v9
    experimentalForceLongPolling: true
  });

  const db = getFirestore(app);

  const storage = getStorage(app);

  const value = { user, auth, functions, isRecognizedUser, db, profile, storage };

  React.useEffect(() => {
    // connectFunctionsEmulator(functions, "localhost", 5001);
    // connectFirestoreEmulator(db, "localhost", 8085)
    // connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });

    const unsubscribe = auth.onAuthStateChanged(function (user) {
      console.log(user);

      if (user) {
        let uid = user.uid;

        setIsRecognizedUser(!user.isAnonymous);

        user.getIdTokenResult().then((idTokenResult) => {
          var recogUser: User = {
            uid: uid,
            email: idTokenResult.claims.userEmail as string,
            emailVerified: idTokenResult.claims.emailVerified as unknown as boolean || false
          };

          setUser(recogUser);
        });


        setProfile(FirebaseFirestoreUtil.getUserProfile(db, user.uid));
      } else {
        console.log("Signing in signInAnonymously");
        FirebaseAuthUtil.signInAnonymously(auth);
      }
    }, function (error) {
      console.log(error);
    });
    return unsubscribe;
  }, [])

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useFirebaseAuth must be used within a FirebaseAuthProvider");
  }
  return context;
}

export { FirebaseProvider, useFirebase };
